import React, { useRef, useState } from 'react';
import { Box, Stack, styled } from '@mui/system';
import ClimateTool, { ClimateMethodHandles } from 'sweep-journey';
import SwSweepJourneyController from '@lib/components/SwSweepJourneyControllers';
import { grey5, skyBlueStrong } from '@lib/components/bedrock/SwColors';
import SwButton, { SwButtonKind } from '@lib/components/bedrock/button/SwButton';
import SwInput, { SwInputKind } from '@lib/components/bedrock/form/SwInput';
import SwInputLabel from '@lib/components/bedrock/form/SwInputLabel';
import { SwSelect, SwSelectOption } from '@lib/components/bedrock/form/SwSelect';
import { isBrowser } from '@app/common/helpers/gatsby';
import { usePlatformContainer } from '@app/platform/PlatformProvider';
import Scene from '../../assets/journey/models/gltf/scene.glb';
import Depth from '../../assets/journey/textures/depth.png';
import ScannerIcons from '../../assets/journey/textures/scanner-icons.png';

const TopCard = styled(Box)`
    z-index: 9;
    background: rgba(249, 249, 249, 0.8);
    border: 1px solid ${grey5};
    width: auto;
    border-radius: 8px;
    position: absolute;
    top: 16px;
`;

const BRANDFETCH_API_KEY = process.env.GATSBY_SWEEP_BRANDFETCH;
const BRANDFETCH_ENABLED = process.env.GATSBY_SWEEP_ENV !== 'development';

const MainContent = () => {
    const textures = { depth: Depth, scanner: ScannerIcons };
    const toolRef = useRef<ClimateMethodHandles>(null);
    const viewportOffset = isBrowser() && { x: window.innerWidth / 100, y: window.innerHeight / 100 };
    const [state, dispatch] = usePlatformContainer();
    const { climateTool } = state;
    const [brandData, setBrandData] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [sector, setSector] = useState('sector0');

    const fetchLogo = async (e) => {
        e.preventDefault();

        if (BRANDFETCH_ENABLED && inputValue.length > 0) {
            fetch(`https://api.brandfetch.io/v2/brands/${inputValue}`, {
                method: 'get',
                headers: new Headers({
                    Authorization: `Token ${BRANDFETCH_API_KEY}`
                })
            })
                .then(
                    (res) => res.json(),
                    (error) => console.error(error)
                )
                .then((data) => {
                    setBrandData(data);
                });
        }
    };

    const logo = brandData?.logos?.[0]?.formats?.[0].src;
    const color = brandData?.colors?.find((color) => color.type === 'accent')?.hex ?? null;

    return (
        <>
            <Box
                bgcolor={grey5}
                bottom={0}
                display={'grid'}
                height={'100vh'}
                left={0}
                position={'absolute'}
                right={0}
                style={{ placeItems: 'center' }}
                top={0}
                width={'100vw'}
            >
                <ClimateTool
                    assetPath={''}
                    backgroundColor={grey5}
                    brandColor={color ?? skyBlueStrong}
                    gltf={Scene}
                    industrySector={sector}
                    logoUrl={logo}
                    maxZoom={climateTool.zoomSetting.max}
                    minZoom={climateTool.zoomSetting.min}
                    paused={false}
                    ref={toolRef}
                    snowMode={state.climateTool.snowMode}
                    state={{ ...climateTool, activeArea: 0 }}
                    textures={textures}
                    viewportOffset={viewportOffset}
                    zoom={climateTool.zoom}
                    onWebGLStarted={() => dispatch({ type: 'initializeClimateTool' })}
                    onZoom={(zoom) => dispatch({ type: 'onZoomChange', payload: zoom })}
                    // eslint-disable-next-line no-empty-function
                    onZoomTransitionEnd={() => {}}
                />
            </Box>
            <TopCard left={'16px'}>
                <SwSweepJourneyController>
                    <SwSweepJourneyController.ZoomControls
                        onZoomIn={() => dispatch({ type: 'zoomIn' })}
                        onZoomOut={() => dispatch({ type: 'zoomOut' })}
                    />
                    <SwSweepJourneyController.ResetControl onReset={() => dispatch({ type: 'zoomReset' })} />
                    <SwSweepJourneyController.SnowControls
                        isSnowing={state.climateTool.snowMode}
                        onSnowToggle={() => dispatch({ type: 'toggleSnowMode' })}
                    />
                </SwSweepJourneyController>
            </TopCard>
            <TopCard left={'unset'} p={1} right={'16px'}>
                <Stack spacing={2}>
                    <Box alignItems={'center'} component={'form'} display={'flex'} gap={1} onSubmit={fetchLogo}>
                        <SwInput
                            kind={SwInputKind.Default}
                            name={'email'}
                            placeholder={'Enter domain (ex: sweep.net)'}
                            size={'small'}
                            style={{ height: 32 }}
                            type={'text'}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                        <SwButton kind={SwButtonKind.Primary} type={'submit'}>
                            Update
                        </SwButton>
                    </Box>

                    <Box alignItems={'start'} display={'flex'} flexDirection={'column'} gap={0.5}>
                        <SwInputLabel>Sector</SwInputLabel>
                        <SwSelect
                            fullWidth={true}
                            size={'small'}
                            value={sector}
                            onChange={(e) => {
                                setSector(e.target.value as string);
                            }}
                        >
                            {[
                                'sector0',
                                'sector1',
                                'sector2',
                                'sector3',
                                'sector4',
                                'sector5',
                                'sector6',
                                'sector7',
                                'sector8',
                                'sector9',
                                'sector10',
                                'sector11',
                                'sector12',
                                'sector13'
                            ].map((s) => (
                                <SwSelectOption key={s} value={s}>
                                    {s.replace('sector', 'Sector ')}
                                </SwSelectOption>
                            ))}
                        </SwSelect>
                    </Box>
                </Stack>
            </TopCard>
        </>
    );
};

export default MainContent;
