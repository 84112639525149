import React from 'react';
import Seo from '@app/common/components/Seo';
import { isBrowser } from '@app/common/helpers/gatsby';
import MainContent from '@app/island/MainContent';
import PlatformProvider from '@app/platform/PlatformProvider';

const SweepJourney = () => {
    if (!isBrowser()) {
        return null;
    }

    return (
        <PlatformProvider>
            <MainContent />
        </PlatformProvider>
    );
};

export const Head = ({ location: { pathname } }) => <Seo noIndex={true} pathname={pathname} />;

export default SweepJourney;
